import Modal from '@/Components/Common/Modal.jsx';
import NumberTextField from '@/Components/Fields/NumberTextField.jsx';
import CloseIcon from '@mui/icons-material/Close';
import {Box, IconButton, InputAdornment, MenuItem, TextField, Typography, useTheme} from '@mui/material';
import React, {memo, useMemo, useRef} from 'react';

const repayment_periods_options = [
    {label: '10 ans', value: 120},
    {label: '15 ans', value: 180},
    {label: '20 ans', value: 240},
    {label: '25 ans', value: 300},
];

const ModalBudgetDetails = ({open, data, errors, budget_allowance, amount_max_monthly, onChange, onClose}) => {
    const modal_id = 'modal-budget-details';
    const current_theme = useTheme();

    const budget_details_form = useRef(null);

    const budget_allowance_width = useMemo(
        () =>
            budget_allowance === ''
                ? '100%'
                : ((budget_allowance + '').length + 1) * current_theme.typography.htmlFontSize,
        [budget_allowance],
    );

    //useRenderCount('ModalBudgetDetails', data);

    const handleClose = () => {
        onClose();
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        onChange(name, value);
    };

    const handleControlledChange = ({name, value}) => {
        onChange(name, value);
    };

    return (
        <Modal
            keepMounted
            id={modal_id}
            ref={budget_details_form}
            open={open}
            isForm={true}
            onClose={handleClose}
            title={
                <>
                    <Box>Détail du budget</Box>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                </>
            }
            DialogTitleProps={{
                sx: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'between',
                },
            }}
        >
            <Box sx={{mt: 2}}>
                <TextField
                    id={modal_id + '-budget_allowance'}
                    type="number"
                    name="budget_allowance"
                    label="Budget estimé"
                    value={budget_allowance}
                    fullWidth
                    readOnly
                    required
                    slotProps={{
                        label: {
                            shrink: false,
                            sx: {
                                whiteSpace: 'nowrap',
                                overflow: 'visible',
                                width: '100%',
                            },
                        },
                        input: {
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            sx: {
                                width: budget_allowance_width,
                            },
                        },
                    }}
                    sx={{
                        ...(budget_allowance === '' ? {display: 'none'} : {}),
                        mb: 2,
                    }}
                />
                <NumberTextField
                    id={modal_id + '-down-payment'}
                    name="down_payment"
                    label="Apport"
                    value={data.down_payment}
                    fullWidth
                    required
                    error={!!errors?.down_payment}
                    helperText={errors.down_payment}
                    step="1"
                    min="0"
                    slotProps={{
                        input: {
                            step: '1',
                            min: '0',
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        },
                    }}
                    sx={{mb: 2}}
                    onChange={handleControlledChange}
                />
                <NumberTextField
                    id={modal_id + '-interest-rate'}
                    name="interest_rate"
                    label="Taux d'intérêt annuel"
                    value={data.interest_rate}
                    fullWidth
                    required
                    error={!!errors?.interest_rate}
                    helperText={errors.interest_rate}
                    step="0.01"
                    min="0"
                    slotProps={{
                        input: {
                            step: '0.01',
                            min: '0',
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        },
                    }}
                    sx={{mb: 2}}
                    onChange={handleControlledChange}
                />
                <NumberTextField
                    id={modal_id + '-insurance-rate'}
                    name="insurance_rate"
                    label="Taux d'assurance"
                    value={data.insurance_rate}
                    fullWidth
                    required
                    error={!!errors?.insurance_rate}
                    helperText={errors.insurance_rate}
                    step="0.01"
                    min="0"
                    slotProps={{
                        input: {
                            step: '0.01',
                            min: '0',
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        },
                    }}
                    sx={{mb: 2}}
                    onChange={handleControlledChange}
                />

                <TextField
                    select
                    id={modal_id + '-repayment-period'}
                    name="repayment_period"
                    label="Durée de remboursement"
                    value={data.repayment_period}
                    fullWidth
                    required
                    error={!!errors?.repayment_period}
                    helperText={errors.repayment_period}
                    onChange={handleChange}
                >
                    {repayment_periods_options.map(({label, value}) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box sx={{p: 2, display: 'flex', justifyContent: 'center'}}>
                <Typography variant="h4" component="span" sx={{textAlign: 'center', fontWeight: 600}}>
                    À partir de <br /> {amount_max_monthly} € / mois *
                </Typography>
            </Box>
        </Modal>
    );
};

export default memo(ModalBudgetDetails);
